export const colors = {
  white: "#ebeeef",
  black: "#000",
  // background: '#292a24',
  background: "#ebeeef",
  primary: "#333c2f",
  secondary: "#333c2f",
  text: "#333c2f",
  accent: "lightCyan",
};

//  '#2b3b33'
//  '#34495e'
//  '#3a5140'
//  '#445b26'
//  '#4f5e6d'
//  '#4f6077'
//  '#516d59'
//  '#6c6e25'
//  '#768183'
//  '#baab58'
//  '#c0beaa'
//  '#dfd4b7'
